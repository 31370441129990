import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/content"
import Event from "../components/event"
import { graphql } from "gatsby"
import Img from "gatsby-image"

const TrainingEvents = ({location, data}) => {
  return (
    <Layout pageTitle="Training &amp; Events" pathName={location.pathname}>
      <SEO title="Training and Events" />

      <Content>
        <Img fluid={data.eventsImage.childImageSharp.fluid} />
        <h2>2022 Events</h2>
        <Event
          date="September 28"
          title="Midwest Cargo Security Council Cargo Theft Conference"
          location="Plainfield, IL"
          url="https://www.midwestcargosecuritycouncil.com/">

          <strong>For NICB cargo or other industry events, go to <a href="https://www.nicb.org/news/events">nicb.org/news/events</a>.</strong>
        </Event>
      </Content>
    </Layout>
  )
}

export default TrainingEvents

export const query = graphql`
  query {
    eventsImage: file(relativePath: { eq: "events-2x.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

