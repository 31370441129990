import React from "react"

const Event = ({date, location, title, url, children}) => {

  let eventStyles = {
    marginTop: '20px',
  };

  let linkStyles = {
    display: 'inline-block'
  }

  let extraInfoStyles = {
    fontSize: '14px',
    marginTop: '5px'
  }

  let eventContent = (
    <div className="event-content">
      <div className="event-date">{date}</div>
      <h3 className="event-title">{title}</h3>
      <div className="event-location">{location}</div>
    </div>
  );

  if (url) {
    eventContent = (
      <a style={linkStyles} href={url} rel="noopener noreferrer">
        {eventContent}
      </a>
    );
  }

  return (
    <article style={eventStyles} className="product product--type-event product--view-mode-content-summary-big">
      {eventContent}

      {
        (children) ?
        <div style={extraInfoStyles} className="event-extra-info">{children}</div> : ""
      }

    </article>
  )
}

export default Event
